import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { SIconButton, cn, } from 'tailwind-ui'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { useInView } from 'react-intersection-observer'
import { useHideZendesk } from '@/helpers/zendesk'
import { ScrollArea } from '@/common/scroll-area'

function Drawer({
  open = true,
  setOpen,
  children,
  width = 'w-full lg:max-w-7xl',
  title = '',
  classNames = {
    container: 'lg:max-w-7xl',
  },
}: {
  open: boolean
  setOpen: (isOpen: boolean) => void
  children: React.ReactNode
  width?: string
  title: string
  classNames?: {
    container: string
  }
}) {
  const handleDrawerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const [inViewRef, isInView] = useInView()

  useHideZendesk(isInView)

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        className="fixed inset-0 duration-300 ease-out data-[closed]:opacity-0 bg-white/50 dark:bg-black/50 backdrop-blur-md"
        transition
      />

      <div className="fixed right-0 inset-0 w-screen  h-auto p-2">
        <div className="flex min-h-full justify-end">
          <DialogPanel
            transition
            className={cn(`flex flex-col h-[calc(100vh-1rem)] overflow-hidden mb-4 z-50 pointer-events-auto w-screen shadow-md ${width} bg-white/75 dark:bg-black/75 backdrop-blur-none lg:border rounded-3xl border-black/10 dark:border-white/10 duration-300 ease-out data-[closed]:translate-x-full`, classNames.container)}
            ref={inViewRef}
          >
            <div className="sticky rounded-t-3xl -top-4 z-10 flex px-2 py-1.5 min-h-[54px] justify-between items-center bg-white dark:bg-black">
              <SIconButton
                icon={XMarkIcon}
                variant="ghost_default"
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
              />
              <DialogTitle
                as="h3"
                className="text-base font-semibold capitalize leading-6 text-gray-900 dark:text-gray-50"
              >
                {title}
              </DialogTitle>
              <div className="w-[40px]" />
            </div>
            <div className="flex-1 h-full overflow-y-auto">
              {children}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
export default Drawer
